<h2 mat-dialog-title class="headerColor">{{data.titleName}}</h2>
<div class="icon">
    <img src="../../../../assets/images/close.png" (click)="close()" class="close-icon" />
</div>
<mat-dialog-content>
    <div class="scrollable-container">
        <div>
            <div>
                <div class="example-full-width my-3">
                    <label>Order</label><span class="text-danger">*</span>
                    <input class="form-control" type="number" [(ngModel)]="order" name="order" required>
                </div>
                <div class="example-full-width my-3">
                    <label>Title</label><span class="text-danger">*</span>
                    <input class="form-control" type="text" [(ngModel)]="title" name="title" maxlength="50" required>
                </div>
                <div class="example-full-width my-3">
                    <label>Description </label><span class="text-danger">*</span>

                    <textarea class="form-control" [(ngModel)]="description" name="description" id="" cols="30"
                        maxlength="1000" rows="5">
                        </textarea>

                    <div class="text-muted" *ngIf="description">{{ 1000 - description.length }}/1000 </div>
                </div>
                <div class="example-full-width my-3">
                    <label>Image</label><span class="text-danger">*</span>
                    <div class="d-flex align-items-center">
                        <input #inputRef class="d-none form-control" type="file" name="imageUrl"
                            (change)="onFileSelected($event)" multiple="false" required>
                        <img class="imageSize" (error)="changeSource($event)" title="upload image"
                            (click)="inputRef.click()" src={{this.imageUrl}} alt="userImage">
                    </div>
                </div>
            </div>

            <div class="example-full-width my-3">
                <label>Profession Type</label><span class="text-danger">*</span>

                <div class="d-flex">
                    <input class="mx-1" type="checkbox" [(ngModel)]="professionType1"
                        (change)="onCheckboxChange($event, 'CHIROPRACTOR')">
                    <span class="me-2">Chiropractor</span>

                    <input class="mx-1" type="checkbox" [(ngModel)]="professionType2"
                        (change)="onCheckboxChange($event, 'PHYSIOTHERAPIST')">
                    <span class="me-2">Physiotherapist</span>

                    <input class="mx-1" type="checkbox" [(ngModel)]="professionType3"
                        (change)="onCheckboxChange($event, 'MASSAGE_THERAPIST')">
                    <span class="me-2">Massage Therapist</span>

                </div>


            </div>

        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button type="submit" [disabled]="!imageUploaded && !isEdit" class="btnText" color="primary"
        (click)="submit()">{{ isEdit ? 'Update' : 'Add'
        }}</button>
    <button mat-raised-button (click)="close()">Close</button>
</mat-dialog-actions>