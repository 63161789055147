import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UserService } from "../../services/user.service";
import { ToasterService } from "../../services/toaster.service";

@Component({
	selector: "app-add-expertise",
	templateUrl: "./add-expertise.component.html",
	styleUrl: "./add-expertise.component.css",
})
export class AddExpertiseComponent implements OnInit {
	professionTypes: string[] = [];
	order: any;
	title: any;
	description: any;
	imageUrl: any;
	fileName: any;
	fileData: any;
	professionType1 = false;
	professionType2 = false;
	professionType3 = false;
	file: any;
	fileDocumentId = 0;
	isEdit = false;
	id = 0;
	imgUrl: any;
	imageUploaded: boolean = false;

	constructor(
		public dialogRef: MatDialogRef<AddExpertiseComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private userService: UserService,
		private toasterService: ToasterService
	) {}

	ngOnInit(): void {
		this.isEdit = this.data.isEdit;
		if (this.isEdit) {
			this.order = this.data.rowData.order;
			this.title = this.data.rowData.title;
			this.description = this.data.rowData.description;
			this.imageUrl = this.data.rowData.imageUrl;
			this.id = this.data.rowData.id;
			this.professionType1 =
				this.data.rowData.professionalType.includes("Chiropractor");
			this.professionType2 =
				this.data.rowData.professionalType.includes("Physiotherapist");
			this.professionType3 =
				this.data.rowData.professionalType.includes("Massage Therapist");
		}

		if (this.professionType1) {
			this.professionTypes.push("CHIROPRACTOR");
		}
		if (this.professionType2) {
			this.professionTypes.push("PHYSIOTHERAPIST");
		}
		if (this.professionType3) {
			this.professionTypes.push("MASSAGE_THERAPIST");
		}
	}

	onFileSelected(event: any) {
		this.file = event.target.files[0];
		if (this.file) {
			this.onFileUpload();
		}
	}

	close() {
		this.dialogRef.close();
	}

	onCheckboxChange(event: any, profession: string) {
		if (event.target.checked) {
			this.professionTypes.push(profession);
		} else {
			const index = this.professionTypes.indexOf(profession);
			if (index !== -1) {
				this.professionTypes.splice(index, 1);
			}
		}
	}

	onFileUpload() {
		const formData = new FormData();
		formData.append("image", this.file);
		this.addImage(formData);
	}
	changeSource(event: any) {
		event.target.src =
			"https://papaya-health.s3.ca-central-1.amazonaws.com/papaya/USER/images/1715583811878_user.png";
	}

	addImage(formData: any) {
		this.userService.userImage(formData).subscribe(
			(res: any) => {
				this.fileDocumentId = res.body.data.documentId;
				this.imageUrl = res.body.data.imageUrl;
				this.imageUploaded = true;
				this.toasterService.success("File Upload sucessfully.");
			},
			(err) => {
				this.toasterService.error("Invalid Image Format or size");
			}
		);
	}

	submit() {
		let payload;
		if (!this.isEdit) {
			payload = {
				order: this.order,
				title: this.title,
				description: this.description,
				imageId: this.fileDocumentId,
				professionTypes: this.professionTypes,
			};

			if (this.order <= 0) {
				this.toasterService.warning("Order must be a number greater than 0");
				return;
			}
			if (
				!payload.order ||
				!payload.title ||
				!payload.description ||
				!payload.imageId ||
				payload.professionTypes.length === 0
			) {
				this.toasterService.warning("Please fill the required fields");
				return;
			}
		} else if (this.isEdit) {
			payload = {
				order: this.order,
				title: this.title,
				description: this.description,
				imageId: this.fileDocumentId !== 0 ? this.fileDocumentId : undefined,
				professionTypes: this.professionTypes,
			};
			if (this.order <= 0) {
				this.toasterService.warning("Order must be a number greater than 0");
				return;
			}
			if (
				!payload.order ||
				!payload.title ||
				!payload.description ||
				payload.professionTypes.length === 0
			) {
				this.toasterService.warning("Please fill the required fields");
				return;
			}
		}
		this.dialogRef.close(payload);
	}
}
